import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import Seo from '../components/seo'
import MyMap from '../components/mymap'
import SeasonSchedule from '../components/season-schedule'
import schedule from '../schedules/spring-2023.json'

import '../scss/page.scss'

const moment = require('moment')
moment.locale('fi_FI')

const InfoPage = () => {
  return (
    <Layout>
      <Seo title="Info" bodyId={'page-info'} />
      <div className={'page-block'}>
        <h2 className="uk-heading-small">Meriselitys</h2>
        <p className="uk-text-medium">
          Olemme joukko itseään enemmän tai vähemmän kristityksi epäileviä
          mieshenkilöitä läntiseltä Vantaalta. Keräännymme kokoon muutaman
          viikon välein{' '}
          <a
            href={'https://www.vantaanseurakunnat.fi/vantaankosken-seurakunta'}
            target="_blank"
            rel="noopener noreferrer"
          >
            Vantaankosken seurakunnan
          </a>{' '}
          tiloihin. Osallistujien ikähaitari on noin 35 - 100 vuotta.
        </p>
        <p className="uk-text-medium">
          31.10.2022 alkaen kokoonnumme taas <a
            href={
              'https://www.vantaanseurakunnat.fi/kirkot-ja-tilat/seurakuntatilat/martinristi'
            }
            target="_blank"
            rel="noopener noreferrer"
          >
            Martinristin seurakuntatilassa
          </a>. Aloitamme kunkin illan aina kello 18:30. Ovet avataan
          noin kello 18:15.
        </p>

        <p className="uk-text-medium">
          Kukin noin puolentoista tunnin kokoontuminen kietoutuu yhden ennalta
          valitun aiheen ympärille. Aiheet voivat olla sekä hengellisiä että
          maallisia. Lyhyehkön, maksimissaan noin 45 minuutin mittaisen
          alustuksen päätteeksi keskustellaan. Kokoontumiset päätämme yhteiseen
          rukoukseen tai iltavirteen.
        </p>

        <p className="uk-text-medium uk-margin-medium-bottom">
          Olet lämpimästi tervetullut mukaan.
        </p>

        <MyMap></MyMap>

        <h2 className="uk-heading-tiny">Yhteys</h2>
        <p className="uk-text-medium">
          Kaipaisitko lisätietoja? Onko mielessäsi mehevä aihe, jonka haluaisit
          jakaa muiden kanssa? Laita rohkeasti sähköpostia osoitteeseen{' '}
          <strong>kuisma@miestenpiiri.eu</strong>. Tattista!
        </p>

        <h2 className="uk-heading-tiny">Kevään 2023 ohjelma</h2>
        <SeasonSchedule schedule={schedule} />
      </div>
    </Layout>
  )
}

export default InfoPage

export const topics = graphql`
  query {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: ASC }
      filter: {
        frontmatter: {
          type: { eq: "summary" }
          date: { gt: "2020-09-01" }
          tags: { ne: "test" }
        }
      }
    ) {
      edges {
        node {
          fields {
            slug
          }
          ...summaryFrontmatterFields
        }
      }
    }
  }
`
