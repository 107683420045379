import React, { useEffect, useState } from 'react'
import './mymap.scss'

const helpers = require('../components/helpers')

// Make sure the Leaflet map is not rendered on server side
const L = helpers.isBrowser() ? require('leaflet') : null

function MyMap() {
  const [mapData, setMapData] = useState(null)

  const getMap = async () => {
    console.log('fetching the map')
    const response = await fetch(
      'https://nominatim.openstreetmap.org/search?' +
        new URLSearchParams({
          street: '2 Martinpolku',
          housenumber: '2',
          postalcode: '01620',
          city: 'Vantaa',
          format: 'json',
        }),
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      }
    )

    return response.json()
  }

  // Run this once
  useEffect(() => {
    const asyncFetch = async function() {
      const response = await getMap()

      setMapData(response)
    }

    asyncFetch()
  }, [])

  useEffect(() => {
    if (mapData !== null) {
      // Init new map
      const map = L.map('map', {
        center: ['60.27695', '24.8471'],
        zoom: 16,
        tileSize: 350,
      })

      L.marker(['60.27695', '24.8471'], {
        title: 'Martinristi',
        alt: 'Martinristi',
      }).addTo(map)

      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png?', {
        attribution:
          'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>',
      }).addTo(map)

      // When component unmounts (Page / data refresh)
      return () => map.remove()
    }
  }, [mapData])

  return (
    <div className={'map-wrapper'}>
      <div id={'map'}></div>
      <ul className='uk-list uk-list-collapse'>
        <li className='uk-text-medium'>Martinristin seurakuntatila</li>
        <li className='uk-text-medium'>Martinpolku 2 C</li>
        <li className='uk-text-medium'>01620 Vantaa</li>
      </ul>
    </div>
  )
}

export default MyMap
