import React from 'react'

const moment = require('moment')
moment.locale('fi_FI')

function SeasonSchedule(props) {
  const { schedule, year, semester } = props

  const translatedSemester = semester === 'autumn' ? 'Syksy' : 'Kevät'

  return (
    <div>
      <table className="uk-table uk-table-divider">
        {year && semester && (
          <caption>
            <span className="uk-text-large uk-text-bold uk-text-emphasis">
              {`${translatedSemester} ${year}`}
            </span>
          </caption>
        )}
        <thead>
          <tr>
            <th>Ajankohta</th>
            <th>Alustaja(t)</th>
            <th>Aihe</th>
          </tr>
        </thead>
        <tbody>
          {schedule.map((event, key) => {
            return (
              <tr key={key}>
                <td>
                  {moment(event.datetime).format('DD.MM.YYYY [klo] HH:mm')}
                </td>
                <td>{event.speaker}</td>
                <td>{event.title}</td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

export default SeasonSchedule
